<template>
  <span :class="bem('', [type, variant])">
    <slot />
  </span>
</template>
<script>
import Bem from '@predicthq/vue3.utils.mixin-bem'

export default {
  name: 'AppChip',
  mixins: [Bem],
  props: {
    type: {
      type: String,
      default: 'chip',
      validator: function (value) {
        return ['chip', 'tag'].indexOf(value) !== -1
      },
    },
    variant: {
      type: String,
      default: 'default',
      validator: function (value) {
        return (
          ['default', 'purple', 'pink', 'pastel-pink', 'pastel-blue', 'pastel-purple', 'grey'].indexOf(value) !== -1
        )
      },
    },
  },
}
</script>
<style lang="scss" scoped>
@import './app-chip.scss';
</style>
