<template>
  <portal to="modal" :disabled="disablePortal">
    <transition :name="transition">
      <dialog v-show="open" v-bind="$attrs" :class="bem('', { open })" :id="`modal-${uuid}`">
        <slot />
      </dialog>
    </transition>
  </portal>
</template>

<script>
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import bem from '@predicthq/vue3.utils.mixin-bem'
import uuid from '@predicthq/vue3.utils.mixin-uuid'

export default {
  name: 'Modal',
  mixins: [bem, uuid],
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    disablePortal: Boolean,
    persistent: Boolean,
    transition: [String],
  },
  computed: {
    modal() {
      // Refs are causing some weird behavior in a few scenarios, which
      // could be related to a portal caveat caused by delayed renders
      // https://portal-vue.linusb.org/guide/caveats.html#refs
      //
      // This seems to be an issue particularly for situations where
      // you're on a page that has a modal and navigate to another
      // page using a modal. Which causes the modal ref to be undefined
      // To circumvent, we select the dialog with a unique id.
      return document.getElementById(`modal-${this.uuid}`)
    },
  },
  methods: {
    show() {
      this.$emit('before-open')
      if (this.modal.showModal) this.modal.showModal()
      disableBodyScroll(this.modal)
    },
    close() {
      // Need to check if the element is open, otherwise
      // this causes an exception on browsers that use the polyfill.
      if (this.modal.open) {
        this.modal.close()
        this.onClose()
      }
    },
    onClose() {
      enableBodyScroll(this.modal)
    },
    async prepare() {
      await this.$nextTick() // need to wait for portal to make the `modal` available in the DOM

      if (!this.modal.showModal) {
        const dialogPolyfill = (await import('dialog-polyfill')).default
        dialogPolyfill.registerDialog(this.modal)
      }

      if (this.open) this.show()

      // If modal is persistent, prevent propagation when pressing ESC
      this.modal.addEventListener('cancel', (e) => {
        if (this.persistent) e.preventDefault()
      })

      // Native dialogs can be closed by escape presses, so listen for this
      this.modal.addEventListener('close', (e) => {
        this.$emit('close')
        this.onClose()
      })

      // Also listen for clicks on the backdrop to close
      this.modal.addEventListener('click', (e) => {
        // There's currently no way to determine whether the backdrop has
        // been clicked, so check if the dialog is the event target - clicks
        // on children inside the dialog won't trigger this.
        if (!this.persistent && e.target === this.modal) {
          this.$emit('close')
          this.onClose()
        }
      })
    },
  },
  watch: {
    async open(value) {
      await this.$nextTick()

      value ? this.show() : this.close()
    },
  },
  mounted() {
    this.prepare()
  },
  beforeUnmount() {
    this.close()
  },
}
</script>

<style lang="scss" src="./modal.scss" module />
