<template>
  <div :class="bem('', [variant, { stacked }])">
    <div :class="bem('wrap')">
      <Icon :class="bem('icon')" v-if="icon" :icon="icon" />
      <div :class="bem('text')">
        <slot />
      </div>
      <div v-if="$slots.action" :class="bem('action')"><slot name="action" /></div>
    </div>
  </div>
</template>
<script>
import bem from '@predicthq/vue3.utils.mixin-bem'
import Icon from '@predicthq/vue3.components.icon'
import { computed } from 'vue'

export default {
  name: 'StatusMessage',
  mixins: [bem],
  props: {
    /**
     * Gives status message variant modifications
     * Allowed strings: 'success', 'error'
     */
    variant: {
      type: String,
      required: true,
      default: 'error',
      validate: (val) => ['success', 'error'].includes(val),
    },
    /**
     * Put the action slot under the text
     * Usefull, when status-message is within small width components
     */
    stacked: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Icon,
  },
  setup(props) {
    const icon = computed(() => {
      if (props.variant === 'success') return 'form/success'
      if (props.variant === 'error') return 'form/error'
      if (props.variant === 'warning') return 'form/warning'
      return null
    })

    return { icon }
  },
}
</script>
<style lang="scss" scoped>
@import './status-message.scss';
</style>
