<template>
  <div :class="bem('', { inline, left })">
    <slot />
    <span :class="bem('content', [variant, color, { overlap, invert }])">
      {{ content }}
    </span>
  </div>
</template>
<script>
import Bem from '@predicthq/vue3.utils.mixin-bem'

export default {
  name: 'AppBadge',
  mixins: [Bem],
  props: {
    /** [default | small]: Small has a white border around it
     * and best suited when used in conjustion with overlap */
    variant: {
      type: String,
      default: 'default',
      validator: function (value) {
        return ['default', 'small'].indexOf(value) !== -1
      },
    },
    /**  content to be displayed inside badge */
    content: {
      type: [String, Number],
      default: '',
    },
    /*
     * Displays badge inline, instead of overlapping the content
     */
    inline: {
      type: Boolean,
      default: false,
    },
    /*
     * Left placemenet of the badge relative to the content
     */
    left: {
      type: Boolean,
      default: false,
    },
    /** [default | small]: Small has a white border around it
     * and best suited when used in conjustion with overlap */
    color: {
      type: String,
      default: 'primary',
      validator: function (value) {
        return ['primary', 'secondary'].indexOf(value) !== -1
      },
    },
    invert: Boolean,
  },
  computed: {
    overlap() {
      return !!this.$slots.default && !this.inline
    },
  },
}
</script>
<style lang="scss" scoped>
@import './app-badge.scss';
</style>
