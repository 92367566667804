<template>
  <Modal v-bind="{ persistent, open }" @close="close" :disablePortal="disablePortal">
    <section :class="bem('', [size, { persistent }])">
      <button v-if="!persistent" :class="bem('close')" @click="close">
        <Icon icon="nav/close" inline color="current" />
      </button>
      <header v-if="$slots.header" :class="bem('header')">
        <slot name="header" />
      </header>
      <div :class="bem('content')">
        <slot />
      </div>
      <footer v-if="$slots.footer" :class="bem('footer')">
        <slot name="footer" />
      </footer>
    </section>
  </Modal>
</template>

<script>
import Icon from '@predicthq/vue3.components.icon'
import Modal from '@predicthq/vue3.components.modal'
import bem from '@predicthq/vue3.utils.mixin-bem'

export default {
  name: 'ModalCard',
  mixins: [bem],
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    disablePortal: Boolean,
    persistent: Boolean,
    size: {
      type: String,
      default: 'regular',
      validator: (val) => ['regular', 'wide'].includes(val),
    },
  },
  components: {
    Modal,
    Icon,
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
@import './modal-card.scss';
</style>
