<template>
  <BaseInput
    v-bind="$props"
    :class="bem('')"
    :focused="isFocused"
    :modelValue="currentValue"
    @clear="setValue('')"
    @click="focus"
    @mousedown="onMouseDown"
  >
    <input
      :type="type"
      ref="input"
      :id="elementId"
      :name="name"
      :readonly="readonly"
      :value="currentValue"
      :disabled="disabled"
      :min="min"
      :max="max"
      :placeholder="placeholder"
      :aria-invalid="error"
      @focus="onFocus"
      @blur="onBlur"
      @input="onInput"
      :autocomplete="autocomplete ? 'on' : 'off'"
    />
    <template #startIcon v-if="$slots.startIcon">
      <slot name="startIcon" />
    </template>
    <template #endIcon v-if="$slots.endIcon">
      <slot name="endIcon" />
    </template>
    <template #endSlot v-if="$slots.endSlot">
      <slot name="endSlot" />
    </template>
  </BaseInput>
</template>

<script>
import bem from '@predicthq/vue3.utils.mixin-bem'
import uuid from '@predicthq/vue3.utils.mixin-uuid'
import BaseInput from '@predicthq/vue3.components.base-input'

export default {
  name: 'AppInput',
  mixins: [bem, uuid],
  components: {
    BaseInput,
  },
  props: {
    /*
      Reuse basic base-input props
    */
    ...BaseInput.props,

    /*
      v-model
    */
    modelValue: {
      type: [String, Number],
    },

    /*
      Name attribute of the input element.
    */
    name: String,

    /*
      Min atrribute - used with type="number"
    */
    min: {
      type: String,
      default: null,
    },

    /*
      Max atrribute - used with type="number"
    */
    max: {
      type: String,
      default: null,
    },

    maxDecimals: {
      type: String,
      default: null,
    },

    /*
      Type attribute of the input element.
    */
    type: {
      type: String,
      default: 'text',
    },

    /*
      Allow browser autocomplete
    */
    autocomplete: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    elementId() {
      return this.id || `input-${this.uuid}`
    },
  },
  data() {
    return {
      /**
       * Internal focused state
       */
      isFocused: false,

      /**
       * Used to keep copy of value to check for min & max before updating v-model.
       * Since app-input can be used for string or number values, we are setting the initial value
       * to match the data type.
       */
      currentValue: typeof this.modelValue === 'number' ? NaN : '',
    }
  },

  watch: {
    modelValue: {
      immediate: true,
      handler(newValue, oldValue) {
        if (
          // Avoid triggering change event when created
          !([null, ''].includes(newValue) && typeof oldValue === 'undefined') &&
          // Avoid infinite loop
          newValue !== this.currentValue
        ) {
          this.setValue(newValue)
        }
      },
    },
  },

  methods: {
    focus(e) {
      if (this.isFocused) return
      this.$refs.input.focus()
    },

    onFocus(e) {
      this.isFocused = true
      this.$emit('focus', e)
    },

    onBlur(e) {
      this.isFocused = false
      this.$emit('blur', e)
    },

    onMouseDown(e) {
      // Prevent input from being blurred
      if (e.target !== this.$refs.input) {
        e.preventDefault()
        e.stopPropagation()
      }
    },

    onInput(e) {
      this.setValue(e.target.value)
    },

    /**
     * Set new value and dispatch input event.
     * @param {number} value - The new value to set.
     */
    setValue(value) {
      const oldValue = this.currentValue
      let newValue = value

      // Only check min / max if type is number
      if (this.type === 'number') {
        if (this.min !== null) {
          newValue = Math.max(this.min, newValue)
        }
        if (this.max !== null) {
          newValue = Math.min(this.max, newValue)
        }

        const strNewValue = newValue ? newValue.toString() : ''
        if (this.maxDecimals !== null && strNewValue.includes('.')) {
          const [number, decimals] = strNewValue.split('.')

          if (decimals.length > this.maxDecimals) newValue = `${number}.${decimals.slice(0, this.maxDecimals)}`
        }
      }

      this.currentValue = newValue

      // Overrides the number in the input box in case it goes over the max/min limits (#13).
      if (value != newValue && this.$refs.input) this.$refs.input.value = newValue

      this.$emit('update:modelValue', newValue, oldValue)
    },
  },
}
</script>

<style lang="scss" scoped>
@import './app-input.scss';
</style>
