<template>
  <div
    :class="
      bem('', [
        {
          error,
          success,
          disabled,
          focused,
          labelShrink: isLabelShrink,
          centered,
          readonly,
          italic,
          clearable,
          hasStartIcon: $slots.startIcon,
        },
        size,
        variant,
      ])
    "
  >
    <label :class="bem('label')" v-if="label" :for="id">{{ label }}</label>

    <div :class="bem('icon', 'start')" v-if="$slots.startIcon">
      <slot name="startIcon" />
    </div>

    <div :class="bem('slot')">
      <slot></slot>
    </div>

    <span v-if="loading" :class="bem('loader')">
      <span :class="bem('loader-dot')"></span>
      <span :class="bem('loader-dot')"></span>
      <span :class="bem('loader-dot')"></span>
    </span>

    <div :class="bem('clear-icon', { show: modelValue })" v-if="clearable" @click="$emit('clear')">
      <Icon icon="form/clear" :size="16" />
    </div>

    <div v-if="error || success" :class="bem('icon', 'validate')">
      <Icon :icon="icon" :size="16" />
    </div>

    <div :class="bem('icon', 'end')" v-if="$slots.endIcon">
      <slot name="endIcon" />
    </div>

    <div :class="bem('endSlot')" v-if="$slots.endSlot">
      <slot name="endSlot" />
    </div>
  </div>
</template>

<script>
import bem from '@predicthq/vue3.utils.mixin-bem'
import Icon from '@predicthq/vue3.components.icon'

export default {
  name: 'BaseInput',
  mixins: [bem],
  components: {
    Icon,
  },
  props: {
    /*
      Value of the input element
    */
    modelValue: [String, Number],

    /*
      Plays the role of placeholder (i.e. sits inside input) 
      when no value is provided.

      Moves up, to overlap the border, when:
      1) value is presented
      2) has a specific prop to keep label up at all times.
      3) input has placeholder
    */
    label: String,

    /*
      If true, the label will always be up
    */
    labelShrink: Boolean,

    /*
      If true, the input element will be disabled.
    */
    disabled: Boolean,

    /*
      If true, the input element is focused.
    */
    focused: Boolean,

    /*
      If true, the label will be displayed in an error state.
    */
    error: Boolean,

    /*
      If true shows success icon.
    */
    success: Boolean,

    /*
      If true, puts input in readonly state
    */
    readonly: Boolean,

    /*
      If true, sets input to be centered
    */
    centered: Boolean,

    /*
      If true, sets input to have an italic state
    */
    italic: Boolean,

    /*
      The id of the input element.
    */
    id: String,

    /*
      Grayed out text / actual placeholder.
      Dissapears when input has value
    */
    placeholder: String,

    /*
      The size variation the input element.
    */
    size: {
      type: String,
      default: 'normal',
      validator: function (value) {
        return ['normal', 'small', 'large'].indexOf(value) !== -1
      },
    },

    /*
      The style variation the input element.
    */
    variant: {
      type: String,
      default: 'primary',
      validator: function (value) {
        return ['primary', 'filled', 'bare'].indexOf(value) !== -1
      },
    },

    /*
      Allows field to be cleared + adds clear icon
    */
    clearable: Boolean,

    /*
      If true, shows loading animation
    */
    loading: Boolean,
  },

  computed: {
    elementId() {
      return this.id || `input-${this.uuid}`
    },
    isLabelShrink() {
      const { labelShrink, modelValue, placeholder, focused, label } = this
      const hasValue = modelValue || parseInt(modelValue) === 0
      return Boolean((labelShrink || hasValue || placeholder || focused) && label)
    },
    icon() {
      if (this.error) return 'form/error'
      if (this.success) return 'form/success'
      return null
    },
  },
}
</script>

<style lang="scss" scoped>
@import './base-input.scss';
</style>
