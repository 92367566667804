<template>
  <Component
    :to="!disabled ? to : null"
    :class="bem('', [variant, color, disableHover ? 'no-hover' : 'hover', { loading, disabled }])"
    :disabled="disabled"
    :is="renderComponent"
  >
    <Loader v-if="loading" :class="bem('loader')" />
    <span :class="bem('content')">
      <span v-if="$slots.startIcon" :class="bem('icon', 'start')"><slot name="startIcon" /></span>
      <slot />
      <span v-if="$slots.endIcon" :class="bem('icon', 'end')"><slot name="endIcon" /></span>
    </span>
  </Component>
</template>
<script>
import Bem from '@predicthq/vue3.utils.mixin-bem'
import BaseButton from '@predicthq/vue3.components.base-button'
import Loader from '@predicthq/vue3.components.loader'

export default {
  name: 'AppLink',
  mixins: [Bem],
  components: {
    BaseButton,
    Loader,
  },
  props: {
    /** to prop, adds the href attribute to the link. */
    to: {
      type: String,
      default: null,
    },
    /** Displays primary or secondary link stylings. */
    variant: {
      type: String,
      default: 'primary',
      validator: function (value) {
        return ['primary', 'secondary'].indexOf(value) !== -1
      },
    },
    /** App link color */
    color: {
      type: String,
      default: 'purple',
      validator: function (value) {
        return ['pink', 'purple'].indexOf(value) !== -1
      },
    },
    /** Using the loading prop, you can notify a user that there is processing taking place. */
    loading: {
      type: Boolean,
      default: false,
    },
    /** Using the disabled prop, you can prevent a user from interacting with the button. */
    disabled: {
      type: Boolean,
      default: null,
    },
    /** using disableHover prop, you can remove the hover state from the element. */
    disableHover: {
      type: Boolean,
      default: false,
    },
    /** Using the component prop will override baseButton */
    component: {
      type: String,
      default: '',
    },
  },
  computed: {
    renderComponent() {
      if (this.component) return this.component
      return 'BaseButton'
    },
  },
}
</script>
<style lang="scss" module src="./app-link.scss" />
