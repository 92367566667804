<template>
  <span :class="bem('', { column })">
    <slot />
  </span>
</template>

<script>
import Bem from '@predicthq/vue3.utils.mixin-bem'

export default {
  name: 'ChipGroup',
  mixins: [Bem],
  props: {
    /*
    column prop controls whether the chip-group will wrap or scroll.
    */
    column: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
<style lang="scss" scoped>
@import './chip-group.scss';
</style>
