<template>
  <span :class="bem('', [color === 'current' ? color : ''])" :style="{ width, height }">
    <component :is="component" :src="path" />
  </span>
</template>
<script>
import bem from '@predicthq/vue3.utils.mixin-bem'
import Icons from '@predicthq/vue3.assets.icons'
import { ref, shallowRef, watchEffect } from 'vue'

export default {
  name: 'Icon',
  mixins: [bem],
  props: {
    /**
     * The icon path from inside "assets/icons" folder.
     * Example icon="common/alert" will use:
     * "assets/icons/common/alert.svg" (Will automatically add ".svg" in the end)
     */
    icon: {
      type: String,
      required: true,
    },
    /**
     * The size pplied to the icon.
     * Default size applied inside scss is 24px
     * but can be configure to inherit font size.
     */
    size: {
      type: [Number, Array, String],
      validator: (value) => {
        if (Array.isArray(value)) {
          // If an array, must precisely have 2 items = width & height
          return value.length === 2
        }

        return true
      },
    },

    /**
     * The color of the component.
     * Has to have `inline` prop to be `true` to work
     */
    color: {
      type: String,
      validator: (value) => ['current', 'primary', 'secondary'].indexOf(value) !== -1,
    },

    /**
     * If true, will render an inline SVG
     */
    inline: {
      type: Boolean,
    },
  },
  computed: {
    width() {
      const { checkSize, size } = this

      if (Array.isArray(size)) {
        return checkSize(size[0])
      }

      return checkSize(size)
    },
    height() {
      const { checkSize, size } = this
      if (Array.isArray(size)) {
        return checkSize(size[1])
      }

      return checkSize(size)
    },
  },
  methods: {
    // Convert to inline style value, depending on size type
    checkSize(size) {
      // If "string" -> return it, if "number" -> conver to string
      return typeof size === 'string' ? size : `${size}px`
    },
  },
  setup(props) {
    const path = ref(null) // Path to the icon, if it's just a `img`
    const component = shallowRef(null)

    watchEffect(async () => {
      const { icon, inline, color } = props
      try {
        const data = await Icons(icon, inline, color)

        if (typeof data === 'object') {
          component.value = data.default
          path.value = null
        }

        if (typeof data === 'string') {
          component.value = 'img'
          path.value = data
        }
      } catch (err) {
        console.error(err)
      }
    })

    return { path, component }
  },
}
</script>

<style lang="scss" module src="./icon.scss" />
