<template>
  <component
    :to="to"
    :is="button ? 'BaseButton' : 'div'"
    :style="getStyle"
    :class="bem('', [getColor, { locked, button: button, inline: inline }])"
  >
    <svg
      v-if="variant !== 'mini'"
      :height="radius * 2"
      :width="radius * 2"
      :viewBox="`0 0 ${radius * 2} ${radius * 2}`"
      :class="[bem('circle')]"
      :style="{ left: `-${stroke / 2}px`, top: `-${stroke / 2}px` }"
    >
      <circle :stroke-width="stroke" :r="normalizedRadius" :cx="radius" :cy="radius" stroke="#F5F4F8" fill="none" />
      <circle
        v-if="!locked"
        :stroke-dasharray="circumference + ' ' + circumference"
        :style="{ strokeDashoffset: strokeDashoffset, transition: `stroke-dashoffset ${durationMs}ms` }"
        :stroke-width="stroke"
        :r="normalizedRadius"
        :cx="radius"
        :cy="radius"
        :class="bem('circle')"
        fill="none"
      />
    </svg>
    <label :class="[bem('label')]">
      <template v-if="!locked">{{ progress }}</template>
      <svg
        :class="bem('lock')"
        v-else
        width="10"
        height="13"
        viewBox="0 0 10 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity=".25" fill-rule="evenodd" clip-rule="evenodd">
          <path
            d="M3.5 3.5a1.5 1.5 0 113 0V5h-3V3.5zM1.5 5H1a1 1 0 00-1 1v6a1 1 0 001 1h8a1 1 0 001-1V6a1 1 0 00-1-1h-.5V3.5a3.5 3.5 0 10-7 0V5zM6 8a1 1 0 01-.5.866V10.5h-1V8.866A1 1 0 116 8z"
            fill="#000"
          />
          <path
            d="M3.5 3.5a1.5 1.5 0 113 0V5h-3V3.5zM1.5 5H1a1 1 0 00-1 1v6a1 1 0 001 1h8a1 1 0 001-1V6a1 1 0 00-1-1h-.5V3.5a3.5 3.5 0 10-7 0V5zM6 8a1 1 0 01-.5.866V10.5h-1V8.866A1 1 0 116 8z"
            fill="#4D1BF3"
            fill-opacity=".25"
          />
        </g>
      </svg>
    </label>
    <label :class="bem('description')">{{ description[type] }}</label>
  </component>
</template>
<script>
import Bem from '@predicthq/vue3.utils.mixin-bem'
import BaseButton from '@predicthq/vue3.components.base-button'

const stroke = 2
const radius = 25
const durationMs = 1000
const normalizedRadius = radius - stroke
const circumference = normalizedRadius * 2 * Math.PI
const strokeDashoffset = circumference
const description = {
  phq: `PHQ`,
  local: `Local`,
  aviation: `Aviation`,
  rank: `Rank`,
}

export default {
  name: 'AppRank',
  mixins: [Bem],
  props: {
    // Rank type for the component to render under the progress.
    type: {
      type: String,
      default: 'phq',
      validator: (value) => {
        // The value must match one of these strings
        return Object.keys(description).indexOf(value) !== -1
      },
    },
    variant: {
      type: String,
      default: 'default',
      validator: (value) => {
        return ['default', 'mini'].indexOf(value) !== -1
      },
    },
    // Counter for the donut.
    progress: {
      type: Number,
      default: 0,
    },
    // Locked prop renders a lock icon and greys out the component.
    locked: {
      type: Boolean,
      default: false,
    },
    to: String,
    button: Boolean,
    inline: Boolean,
  },
  components: {
    BaseButton,
  },
  data() {
    return {
      normalizedRadius,
      circumference,
      strokeDashoffset,
      stroke,
      durationMs,
      description,
      radius,
    }
  },
  computed: {
    getColor() {
      if (this.locked || this.progress === 0) return ''
      if (this.progress < 20) {
        return 'progress-1'
      } else if (this.progress < 40) {
        return 'progress-2'
      } else if (this.progress < 60) {
        return 'progress-3'
      } else if (this.progress < 80) {
        return 'progress-4'
      } else {
        return 'progress-5'
      }
    },
    getStyle() {
      if (this.variant === 'mini') {
        return { width: '32px', height: '29px' }
      } else {
        return { width: `${radius * 2 - stroke}px`, height: `${radius * 2 - stroke}px` }
      }
    },
  },
  watch: {
    progress: {
      handler() {
        this.animateValue()
      },
    },
  },
  methods: {
    animateValue() {
      this.strokeDashoffset = this.circumference - (this.progress / 100) * this.circumference
    },
  },
  mounted() {
    setTimeout(() => {
      this.animateValue()
    }, 400)
  },
}
</script>
<style lang="scss" module src="./app-rank.scss" />
