<template>
  <div :class="bem('', [variant, { fullWidth, fillItems, 'mask-right': maskRight, 'mask-left': maskLeft }])">
    <div @scroll="checkScrollbar" ref="mask" :class="bem('mask')">
      <div ref="inner" :class="bem('inner')">
        <slot />
        <span v-if="variant === 'nav'" ref="indicator" :style="indicatorStyles" :class="bem('indicator')" />
      </div>
    </div>
  </div>
</template>
<script>
import Bem from '@predicthq/vue3.utils.mixin-bem'

export default {
  name: 'AppTabs',
  mixins: [Bem],
  /*
   * Provider - to inject props into children
   */
  provide() {
    return { AppTabs: this }
  },
  props: {
    /*
      v-model
    */
    modelValue: {
      type: [Number, String],
      default: 0,
    },

    /*
      If true, tabs will take up the full width of its container.
    */
    fullWidth: Boolean,

    /*
      If true, tabs will equally fill the width of "fullWidth" tabs 
    */
    fillItems: Boolean,

    /*
      If true, tabs will take up the full width of its container.
    */
    variant: {
      type: String,
      default: 'primary',
      validator: function (value) {
        return ['primary', 'nav', 'ant', 'steps'].indexOf(value) !== -1
      },
    },
  },
  data() {
    return {
      maskRight: false,
      maskLeft: false,
      /*
       * Keeps count of chidlren
       * Used to assign id's to child components
       */
      count: 0,

      /*
       * Replicate of v-model
       * if no v-model present, keeps current selected tab
       */
      internalValue: 0,

      /*
       * Used for indicator
       * Array of items refs
       * [ { id, ref } ]
       */
      internalItems: [],

      /*
       * Indicator style
       * Used for "nav" variation, for selected items
       */
      indicatorStyles: {},
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newValue, oldValue) {
        if (
          // Avoid triggering change event when created
          !(typeof oldValue === 'undefined') &&
          // Avoid infinite loop
          newValue !== this.internalValue
        ) {
          this.select(newValue)
        }
      },
    },
  },

  methods: {
    select(idx) {
      this.internalValue = idx
      this.$emit('update:modelValue', idx)
      this.moveIndicator()
    },
    checkScrollbar() {
      /**
       * Checks and apply a subtle gradient on each sides
       */
      const isOverflow = this.$refs.mask.clientWidth < this.$refs.inner.clientWidth
      const maxScrollLeft = this.$refs.mask.scrollWidth - this.$refs.mask.clientWidth
      const scrollLeft = this.$refs.mask.scrollLeft
      this.maskRight = scrollLeft < maxScrollLeft && isOverflow
      this.maskLeft = scrollLeft > 0 && isOverflow
    },
    /**
     * Move indicator on select, if variation === nav
     */
    moveIndicator() {
      if (this.variant === 'nav') {
        const ref = this.internalItems.find((i) => i.id === this.internalValue).ref
        const left = ref.offsetLeft
        const width = ref.clientWidth

        this.indicatorStyles = { left: `${left}px`, width: `${width}px` }
      }
    },
    handleResize() {
      this.checkScrollbar()
      this.moveIndicator()
    },
  },
  mounted() {
    /**
     * Add EventLisenters to create modifiers for AppTabs
     */
    window.addEventListener('resize', this.handleResize)
    this.$nextTick(this.handleResize)

    // Select tab on mount
    this.select(this.modelValue)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize)
  },
}
</script>
<style lang="scss" src="./app-tabs.scss" />
