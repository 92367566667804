<template>
  <span :class="bem('', color)">
    <span :class="bem('dot')"></span>
    <span :class="bem('dot')"></span>
    <span :class="bem('dot')"></span>
  </span>
</template>
<script>
import bem from '@predicthq/vue3.utils.mixin-bem'

export default {
  name: 'Loader',
  mixins: [bem],
  props: {
    color: {
      type: String,
      default: 'current',
      validator: (value) => ['current', 'primary', 'secondary'].includes(value),
    },
  },
}
</script>
<style lang="scss" src="./loader.scss" module />
