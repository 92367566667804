<template>
  <component :is="component" v-bind="attrs" :class="bem('')">
    <slot />
  </component>
</template>

<script>
import { computed, getCurrentInstance } from 'vue'
import Bem from '@predicthq/vue3.utils.mixin-bem'
import { useLink } from '@predicthq/vue3.utils.link-provider'

/**
 * Base link component without any default styling.
 *
 * Has smart defaults based on the URL passed to the `to` prop,
 * and whether the application has vue-router installed.
 *
 * Generally this component would just be used via passing a
 * `to` prop to BaseButton.
 */
export default {
  name: 'BaseLink',
  mixins: [Bem],
  props: {
    to: {
      type: String,
      required: true,
    },
    /**
     * Whether the link goes to an external website.
     * Defaults to whether `to` starts with "http"
     */
    external: {
      type: Boolean,
      default: undefined,
    },
    /**
     * Whether to open the link in a new tab/window. Defaults
     * to true if the link is external, false if not.
     */
    openInNew: {
      type: Boolean,
      default: undefined,
    },
  },
  setup(props) {
    /*
      TODO: This will also need to handle g-link for Gridsome:
      
      https://gridsome.org/docs/linking/
    
      What I'd suggest doing for this is to have a `LinkProvider` component
      that can be placed at the root of the app that can be used to configure
      the router link tag used for the application routing (+ possibly other settings), 
      using provide/inject:

      https://composition-api.vuejs.org/api.html#dependency-injection

      Obviously, a simpler way would just be to check if the app is using Gridsome,
      but the approach above would be cleaner and allow for more flexibility.

      Update:
      Added link-provider component using vue-composition-api provide / inject.
      To configure link tag, add `provideLink('g-link')` or `provideLink('router-link')` 
      inside App.js setup() function. 
    */

    // Although 'getCurrentInstance' is discouraged, this seems to be an appropriate case
    // as it's the only way to check if the app is using vue-router without throwing
    // warnings by using `useRoute`
    const router = !!getCurrentInstance().appContext.config.globalProperties.$router ? 'router-link' : false
    const link = useLink()

    const external = computed(() => props.external ?? props.to.startsWith('http'))
    const openInNew = computed(() => props.openInNew ?? external.value)

    const component = computed(() => (external.value ? 'a' : link || router || 'a'))

    const attrs = computed(() => {
      const hrefProp = component.value === 'a' ? 'href' : 'to'

      return {
        [hrefProp]: props.to,
        target: openInNew.value ? '_blank' : undefined,
        rel: external.value ? 'noopener noreferrer' : undefined,
      }
    })

    return {
      attrs,
      component,
    }
  },
}
</script>

<style lang="scss" module src="./base-link.scss" />
