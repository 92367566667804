<template>
  <div
    :class="
      bem('', [
        {
          error,
          success,
          disabled,
          focused,
          readonly,
          labelShrink: isLabelShrink,
        },
        variant,
      ])
    "
  >
    <label :for="elementId" :class="bem('label')" v-if="label">{{ label }}</label>
    <textarea
      :id="elementId"
      :class="bem('textarea')"
      :name="name"
      :readonly="readonly"
      :placeholder="placeholder"
      :value="modelValue"
      :rows="rows"
      @focus="onFocus"
      @blur="onBlur"
      @input="$emit('update:modelValue', $event.target.value)"
    ></textarea>
  </div>
</template>
<script>
import bem from '@predicthq/vue3.utils.mixin-bem'
import uuid from '@predicthq/vue3.utils.uuid'

export default {
  name: 'AppTextarea',
  mixins: [bem],
  props: {
    /*
      v-model
    */
    modelValue: {
      type: [String, Number],
    },

    /*
      Controls the height of textarea
    */
    rows: {
      type: Number,
      default: 3,
    },

    /*
      The id of the input element.
    */
    id: String,

    /*
      Plays the role of placeholder (i.e. sits inside input) 
      when no value is provided.

      Moves up, to overlap the border, when:
      1) value is presented
      2) has a specific prop to keep label up at all times.
      3) input has placeholder
    */
    label: String,

    /*
      If true, the input element will be disabled.
    */
    disabled: Boolean,

    /*
      If true, the label will always be up
    */
    labelShrink: Boolean,

    /*
      The name attribute for the input element.
    */
    name: {
      type: String,
    },

    /*
      If true, the label will be displayed in an error state.
    */
    error: Boolean,

    /*
      If true shows success icon.
    */
    success: Boolean,

    /*
      Grayed out text / actual placeholder.
      Dissapears when input has value
    */
    placeholder: String,

    /*
      If true, puts input in readonly state
    */
    readonly: Boolean,

    /*
      The style variation the input element.
    */
    variant: {
      type: String,
      default: 'primary',
      validator: function (value) {
        return ['primary', 'filled', 'bare'].indexOf(value) !== -1
      },
    },
  },
  data() {
    return {
      textarea: '',
      focused: false,
    }
  },
  computed: {
    elementId() {
      return this.id || `textarea-${uuid()}`
    },
    isLabelShrink() {
      const { labelShrink, modelValue, placeholder, label, focused } = this
      return Boolean((labelShrink || modelValue || placeholder || focused) && label)
    },
  },
  methods: {
    onFocus(e) {
      this.focused = true
      this.$emit('focus', e)
    },
    onBlur(e) {
      this.focused = false
      this.$emit('blur', e)
    },
  },
}
</script>
<style scoped lang="scss">
@import './app-textarea.scss';
</style>
