<template>
  <BaseButton
    :class="bem('', [variant, { block, loading, disabled }])"
    :to="!disabled ? to : null"
    :disabled="disabled"
    @click="scrollTo"
  >
    <span v-if="$slots.startIcon && !loading" :class="bem('icon', 'start')">
      <slot v-if="$slots.startIcon" name="startIcon" />
    </span>
    <Loader v-if="loading" :class="bem('loader')" />
    <span :class="bem('text')">
      <slot />
    </span>
    <span v-if="$slots.endIcon && !loading" :class="bem('icon', 'end')">
      <slot name="endIcon" />
    </span>
  </BaseButton>
</template>
<script>
import Bem from '@predicthq/vue3.utils.mixin-bem'

import Loader from '@predicthq/vue3.components.loader'
import BaseButton from '@predicthq/vue3.components.base-button'

export default {
  name: 'AppButton',
  mixins: [Bem],
  components: {
    BaseButton,
    Loader,
  },
  props: {
    /** Set if the button is to be rendered as a link (or router link) */
    to: {
      type: String,
      default: null,
    },
    /** Block buttons extend the full available width. */
    block: {
      type: Boolean,
      default: false,
    },
    /** Buttons can be given 'primary' or 'secondary' styling options. */
    variant: {
      type: String,
      default: 'primary',
      validator: function (value) {
        return ['primary', 'secondary'].indexOf(value) !== -1
      },
    },
    /** Using the loading prop, you can notify a user that there is processing taking place. */
    loading: {
      type: Boolean,
      default: false,
    },
    /** Using the disabled prop, you can prevent a user from interacting with the button. */
    disabled: {
      type: Boolean,
      default: null,
    },
  },
  methods: {
    scrollTo(e) {
      if (new RegExp('^#').test(this.to)) {
        e.preventDefault()
        document.querySelector(this.to)?.scrollIntoView({ behavior: 'smooth' })
      }
    },
  },
}
</script>
<style lang="scss" module src="./app-button.scss" />
