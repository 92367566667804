<template>
  <div :class="bem('', [color, { sparse, 'no-icon': !($slots.icon || icon) }])">
    <div :class="bem('wrap')">
      <div :class="bem('icon-wrap')" v-if="$slots.icon || icon">
        <slot v-if="$slots.icon" name="icon" />
        <span :class="bem('icon-box')" v-else-if="icon">
          <Icon :icon="icon" :class="bem('icon')" color="current" />
        </span>
      </div>
      <div :class="bem('text')">
        <Typography v-if="title" :variant="title.variant || 'sm'" :class="bem('title')">
          {{ title.text || title }}
        </Typography>

        <Typography variant="p-sm" component="div" :class="bem('description')" v-if="$slots.description">
          <slot name="description" />
        </Typography>
      </div>
      <div :class="bem('actions', [alignActions])" v-if="$slots.actions">
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<script>
import bem from '@predicthq/vue3.utils.mixin-bem'
import Icon from '@predicthq/vue3.components.icon'
import Typography from '@predicthq/vue3.components.typography'

export default {
  name: 'ActionPanel',
  components: {
    Icon,
    Typography,
  },
  mixins: [bem],
  props: {
    color: {
      type: String,
      validator: function (value) {
        return ['green', 'grey', 'pastel-pink', 'pastel-blue', 'pastel-purple'].includes(value)
      },
    },
    icon: [String, Object],
    title: [String, Object],
    sparse: Boolean,
    alignActions: {
      type: String,
      validator: (val) => ['start', 'end'].includes(val),
    },
  },
}
</script>

<style lang="scss" module src="./action-panel.scss" />
