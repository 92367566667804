<template>
  <component
    :is="component"
    :class="bem('', [variant, { selected, disabled }])"
    :to="to"
    ref="tab"
    @click="!disabled ? AppTabs.select(id) : null"
  >
    <span :class="bem('counter')" v-if="AppTabs.variant === 'steps'">{{ id + 1 }}</span>
    <span v-else-if="$slots.startIcon" :class="bem('icon', 'start')">
      <slot name="startIcon" />
    </span>
    <div :class="bem('title')">
      {{ title }}
    </div>
    <span v-if="$slots.endIcon" :class="bem('icon', 'end')">
      <slot name="endIcon" />
    </span>
  </component>
</template>
<script>
import Bem from '@predicthq/vue3.utils.mixin-bem'
import BaseButton from '@predicthq/vue3.components.base-button'

export default {
  name: 'AppTab',
  mixins: [Bem],
  inject: ['AppTabs'],
  components: {
    BaseButton,
  },
  data() {
    return {
      id: null,
    }
  },
  props: {
    /*
      Tab title
    */
    title: String,

    /*
      Allow tab to be a link
    */
    to: String,

    /*
      TBC
    */
    onSelect: {
      type: Function,
    },

    /*
    You can provide your own value. Otherwise, we fallback to the child position index.
    */
    value: String,

    /*
    Allow tab to be disabled
    */
    disabled: Boolean,
  },
  computed: {
    selected() {
      return this.id === this.AppTabs.internalValue
    },
    variant() {
      return this.AppTabs.variant
    },
    component() {
      return this.to && !this.disabled ? 'BaseButton' : 'div'
    },
  },
  mounted() {
    const index = this.AppTabs.count++
    this.id = this.value || index

    this.AppTabs.internalItems.push({
      id: this.id,
      ref: this.$refs.tab,
    })
  },
}
</script>
<style lang="scss" src="./app-tab.scss" />
