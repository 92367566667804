<template>
  <div :class="bem('', [align, transition])" :style="{ maxWidth }">
    <div v-for="({ type, total }, i) in getBones" :class="bem('bones', type)" :key="i">
      <div v-for="bone in total" :class="bem('bone')" :key="`${i}-${bone}`"></div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import Bem from '@predicthq/vue3.utils.mixin-bem'

export default {
  name: 'BaseSkeleton',
  props: {
    align: {
      type: String,
      default: 'left',
      validator: (val) => ['left', 'center', 'right'].includes(val),
    },
    bones: {
      type: String,
      default: 'text@3',
    },
    maxWidth: {
      type: String,
      default: '100%',
    },
    transition: {
      type: String,
      default: 'shimmer',
      validator: (val) => ['shimmer', 'none'].includes(val),
    },
  },
  mixins: [Bem],
  setup(_) {
    const getBones = computed(() => {
      const values = []
      const ligaments = _.bones.split(' ')

      for (let ligament of ligaments) {
        const [type, total = 1] = ligament.split('@')
        values.push({ type, total: parseInt(total) })
      }

      return values
    })

    return {
      getBones,
    }
  },
}
</script>

<style lang="scss" scoped>
@import './base-skeleton.scss';
</style>
