<script>
import Bem from '@predicthq/vue3.utils.mixin-bem'
import BaseLink from '@predicthq/vue3.components.base-link'

/**
 * Unstyled base button for all clickable actions to use in order
 * to maintain default button focus/keyboard navigation behaviour.
 *
 * Also provides the flexibility to be output as a link if `to`
 * prop is specified, which in turn allows for all of the flexibility
 * of the BaseLink component, such as using Vue Router or a regular
 * anchor tag based on whether the link is external and Vue Router is
 * set up within the application.
 *
 * Sets the display to inline-flex by default to avoid whitespace issues
 * associated with wrapping images and icons in buttons, and sets the
 * button type to "button" by default to avoid accidental form submission.
 */
export default {
  name: 'BaseButton',
  mixins: [Bem],
  components: { BaseLink },
  props: {
    /**
     * If to is specified, the button becomes a link.
     * This allows button styling to be applied to link components
     * and flexibility to switch components between links and buttons.
     */
    to: String,
  },
}
</script>

<template>
  <BaseLink v-if="to" :to="to">
    <slot />
  </BaseLink>
  <button v-else :class="bem('')" type="button">
    <slot />
  </button>
</template>

<style lang="scss" module src="./base-button.scss" />
