<template>
  <label :class="bem('', { error, disabled, italic })" :for="elementId">
    <input
      type="checkbox"
      :name="name"
      :id="elementId"
      :checked="value"
      @change="onChange($event.target.checked)"
      :disabled="disabled"
      :class="bem('input')"
    />
    <span :class="bem('field')"></span>
    <div>
      <Typography variant="p-sm" :class="bem('label')">
        <template v-if="$slots.default"><slot /></template>
        <template v-else>{{ label }}</template>
      </Typography>
      <Typography variant="p-xs" :class="bem('sub')">{{ sub }}</Typography>
    </div>
  </label>
</template>

<script>
import bem from '@predicthq/vue3.utils.mixin-bem'
import uuid from '@predicthq/vue3.utils.mixin-uuid'
import Typography from '@predicthq/vue3.components.typography'

export default {
  name: 'AppCheckbox',
  inheritAttrs: false,
  mixins: [bem, uuid],
  components: {
    Typography,
  },
  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    error: {
      type: Boolean,
    },
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    sub: {
      type: String,
      default: '',
    },
    italic: Boolean,
  },
  computed: {
    elementId() {
      return this.id || `checkbox-${this.uuid}`
    },
  },
  methods: {
    onChange(checked) {
      if (!this.disabled) this.$emit('input', checked)
    },
  },
}
</script>

<style lang="scss" src="./app-checkbox.scss" />
