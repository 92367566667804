<template>
  <div :class="bem('', [variant, { stacked }, color, { 'no-icon': !iconSrc }])">
    <div :class="bem('content')">
      <Icon v-if="iconSrc" :class="bem('icon')" :icon="iconSrc" />
      <Typography :class="bem('title')" v-if="title" variant="p-sm">{{ title }}</Typography>
      <Typography variant="p-sm" v-if="$slots.default" component="div">
        <slot></slot>
      </Typography>
    </div>

    <div :class="bem('action')" v-if="$slots.action">
      <slot name="action"></slot>
    </div>
  </div>
</template>
<script>
import bem from '@predicthq/vue3.utils.mixin-bem'
import Icon from '@predicthq/vue3.components.icon'
import Typography from '@predicthq/vue3.components.typography'

// Map out icon names to "variant" prop
const iconMap = {
  info: 'common/support',
  error: 'common/alert',
  suggestion: 'brand/logo',
}

export default {
  name: 'AlertPanel',
  mixins: [bem],
  props: {
    /**
     * Sets a custom background for the alert panel
     * Overrides variant colour if set
     */
    color: {
      type: String,
      validator: (value) => {
        return ['pastel-pink', 'pastel-blue', 'pastel-purple'].includes(value)
      },
    },
    /**
     * Sets a custom icon for the alert panel.
     * If not set, but background color is set, the icon would not render
     */
    icon: String,
    /**
     * Put the action slot under the text
     * Usefull, when status-message is within small width components
     */
    stacked: {
      type: Boolean,
      default: false,
    },
    /*
     * Title of the panel
     */
    title: String,
    /**
     * Gives status message variant modifications
     * Allowed strings: 'info', and 'error'
     */
    variant: {
      type: String,
      default: 'info',
      validator: (value) => {
        return ['info', 'error', 'suggestion'].includes(value)
      },
    },
  },
  components: {
    Icon,
    Typography,
  },
  computed: {
    iconSrc() {
      if (this.icon) return this.icon

      // Temporary solution for backwards compatibility. If there's no background color set, then use the variant icon
      if (this.variant && !this.color) return iconMap[this.variant]

      return null
    },
  },
}
</script>
<style lang="scss" src="./alert-panel.scss" module />
