<template>
  <component :is="is" :class="bem('', variant)"><slot /></component>
</template>
<script>
import Bem from '@predicthq/vue3.utils.mixin-bem'

/*
  The component maps the variant prop to a range of different HTML element types. 
  For instance, 4xl to <h1>.
*/
const defaultVariantMapping = {
  '4xl': 'h1',
  '3xl': 'h2',
  '3xl-light': 'h2',
  '2xl': 'h2',
  '2xl-light': 'h2',
  xl: 'h2',
  lg: 'h3',
  md: 'h3',
  sm: 'h3',
  xs: 'h3',
  xxs: 'span',
  event: 'h3',
  p: 'p',
  'p-sm': 'p',
  'p-xs': 'p',
  quote: 'p',
  'quote-sm': 'p',
  'quote-xs': 'p',
  code: 'p',
  th: 'span',
  label: 'h5',
}

export default {
  name: 'Typography',
  mixins: [Bem],
  props: {
    // Applies the theme typography styles
    variant: {
      type: String,
      default: 'p',
      validator: function (value) {
        // console.log(value, Object.keys(defaultVariantMapping).indexOf(value) !== -1)
        return Object.keys(defaultVariantMapping).indexOf(value) !== -1
      },
    },

    // The component used for the root node
    component: {
      type: String,
    },
  },
  computed: {
    is() {
      const { component, variant } = this
      return component || defaultVariantMapping[variant] || 'span'
    },
  },
}
</script>
<style lang="scss" module src="./typography.scss" />
